interface UserProfile {
  id: string
  email: string
  full_name?: string
  avatar_url?: string
  website?: string
  bio?: string
  username?: string
  first_name?: string
  last_name?: string
  phone_mobile?: string
  phone_landline?: string
  profile_complete: boolean
  onboarding_completed: boolean
  updated_at?: string
}

type SupabaseClientType = ReturnType<typeof useSupabaseClient>

export function useUserProfile() {
  const supabase = useSupabaseClient<SupabaseClientType>()
  const profile: Ref<UserProfile | null> = ref(null)
  const loading: Ref<boolean> = ref(false)
  const error: Ref<string | null> = ref(null)

  async function fetchUserProfile(userId: string): Promise<void> {
    loading.value = true
    error.value = null

    try {
      const { data, error: supabaseError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .single()

      if (supabaseError) {
        throw supabaseError
      }

      profile.value = data
    }
    catch (e) {
      error.value = (e as Error).message
      console.error('Error fetching user profile:', e)
    }
    finally {
      loading.value = false
    }
  }

  return {
    profile,
    loading,
    error,
    fetchUserProfile,
  }
}
